.people-page {
  background-image: url('../../assets/background.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  max-width: 1300px;
  margin: 40px auto;
}

.people-page h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #ffd700; 
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
}
.search-filter input::placeholder {
  color: #ffffff; 
  opacity: 0.8; 
}
.search-filter {
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  background-color: rgba(94, 90, 90, 0.8); 
  padding: 10px;
  border-radius: 8px;
}


.search-filter input {
  padding: 12px;
  width: 320px; 
  border: 1px solid #555; 
  border-radius: 8px;
  margin-right: 10px;
  font-size: 16px;
  outline: none;
  background-color: #000; 
  color: #fff; 
  transition: border-color 0.3s;
}


.search-filter select {
  
  padding: 12px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 16px;
  outline: none;
  background-color: #000; 
  color: #fff; 
  transition: border-color 0.3s;
}


.search-filter select:focus {
 
  border-color: #555; 
}


.search-filter select option:hover,
select option:focus {

  background-color: #555; 
  color: #000; 
}







.search-filter button {
  padding: 12px 16px;
  background-color: #000; 
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-right: 10px;
}
.search-filter button:last-child {
  margin-right: 0;
}

.search-filter button:hover {
  background-color: #333;
}



.loading {
  text-align: center;
  font-size: 20px;
  color: #fff;
  padding: 20px;
}

.people-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  background-color: rgba(94, 90, 90, 0.8); 
  padding: 20px;
  border-radius: 12px;
}

.person-card {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.person-card:hover {
  box-shadow: 0 6px 18px rgba(232, 208, 24, 0);
  transform: translateY(-5px);
}

.person-card h3 {
  margin-bottom: 10px;
  font-size: 22px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.person-card p {
  margin: 8px 0;
  font-size: 16px;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #2c2c2c; 
  border: 1px solid #858585; 
  cursor: pointer;
  border-radius: 4px; 
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  color: #fff; 
}

.pagination button:hover {
  background-color: #555; 
  color: #ffd700; 
}

.pagination button.active {
  background-color: #222; 
  color: #ffd700; 
  border: 1px solid #555; 
}

.pagination button:focus {
  outline: none; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}

.page-button {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #000; 
  border: 1px solid #444;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #fff;
}

.page-button:hover {
  background-color: #333; 
  color: #ffd700;
}

.page-button.active {
  background-color: #222; 
  color: #ffd700; 
  border-color: #555; 
}
