.planets-page {
  background-image: url('../../assets/background.png');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  max-width: 1300px;
  margin: 40px auto;
}

.planets-page h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #ffd700; 
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
}



.loading {
  text-align: center;
  font-size: 20px;
  color: #fff;
  padding: 20px;
}

.planets-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.planet-card {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.planet-card:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.planet-card h3 {
  margin-bottom: 10px;
  font-size: 22px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.planet-card p {
  margin: 8px 0;
  font-size: 16px;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #2c2c2c; 
  border: 1px solid #858585; 
  cursor: pointer;
  border-radius: 4px; 
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  color: #fff; 
}

.pagination button:hover {
  background-color: #555; 
  color: #ffd700; 
}

.pagination button.active {
  background-color: #222; 
  color: #ffd700; 
  border: 1px solid #555; 
}

.pagination button:focus {
  outline: none; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}

.page-button {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #000; 
  border: 1px solid #444;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #fff;
}

.page-button:hover {
  background-color: #333; 
  color: #ffd700;
}

.page-button.active {
  background-color: #222; 
  color: #ffd700; 
  border-color: #555; 
}

