.person-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(184, 230, 0, 0.1);
  transition: all 0.3s ease-in-out; 
  overflow: hidden; 
  max-height: 260px; 
 
}

.person-card  h3 {
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 0px; 
}

.person-card p {
  margin: 4px 0;
}



.person-card.expanded {
  padding: 24px;
  border: 3px solid #ffd700; 
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2); 
  max-height: 600px; 
}

.details-button {
  background-color: #000;
  color: #ffd700;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 12px;
  
  transition: background-color 0.3s;
}

.details-button:hover {
  background-color: #555; 
}

.details {
  margin-top: 16px;
  border-top: 1px solid #ddd;
  padding-top: 16px;
}
