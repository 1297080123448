.footer {
    background-color: #1d1d1d; 
    color: #f1f1f1; 
    text-align: center;
    padding: 20px;
    margin-top: 40px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3); 
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer h4 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #ffd700; 
  }
  
  .footer p {
    margin: 5px 0;
    font-size: 18px;
  }
  