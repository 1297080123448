.home-page {
  text-align: center;
  padding: 50px 20px;
  background-color: #333333; 
  color: #f1f1f1; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  margin: 70px auto; 
  max-width: 800px; 
}

.home-page h1 {
  font-size: 36px;
  color: #ffd700; 
  margin-bottom: 20px;
}

.home-page p {
  font-size: 18px;
  color: #cfcfcf; 
  margin-bottom: 30px;
}

.home-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; 
}

.home-link {
  padding: 15px 30px;
  background-color: #0d0d0d;
  color: #f1f1f1; 
  text-decoration: none;
  border-radius: 8px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.home-link:hover {
  background-color: #656562;
}

body {
  background-color: #2b2b2b; 
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #1d1d1d; 
  color: #cfcfcf;
  margin-top: 50px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3); 
}  